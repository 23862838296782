import logo from "./logo-sdg-2.png";
import logoLI from "./logoLI.png";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />

        <div className="input-container">
          <label htmlFor="questionInput" className="label-text">
            <strong>Question / RFI:</strong>{" "}
          </label>

          <button class="email-button" style={{ padding: "5px 15px;" }}>
            <a
              href="mailto:question@see3eye.com"
              style={{ color: "inherit", textDecoration: "none" }}
            >
              Question
            </a>
          </button>

          {/* <input
            type="text"
            id="questionInput"
            name="questionInput"
            placeholder="Ask a Question / Submit an RFI?"
            style={{ width: "300px" }}
          /> */}
        </div>

        <div class="contact-section">
          <p class="inquiry-text">
            <strong>Inquire via:</strong>
          </p>
          <div class="contact-buttons">
            <a
              href="https://www.linkedin.com/company/see3eye/"
              className="linkedin-button"
            >
              <img src={logoLI} alt="LinkedIn" style={{ width: "30px" }} />
            </a>
            <button class="email-button" style={{ padding: "5px 15px;" }}>
              <a
                href="mailto:inquiries@see3eye.com"
                style={{ color: "inherit", textDecoration: "none" }}
              >
                Email
              </a>
            </button>
          </div>
        </div>
      </header>
    </div>
  );
}

export default App;
